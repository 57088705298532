import { v4 as uuidv4 } from "uuid";
import { AppInstance } from "@shared/api/app/app.instance";
import { UserData } from "@shared/types/user-data";
import { ClientResponse } from "@shared/types/clientResponses";
import { PaymentUserInfo } from "@shared/types/paymentUserInfo";

export const appApi = {
  async checkUserForPayment(userData: UserData) {
    return await AppInstance.post<ClientResponse<PaymentUserInfo>>(
      "payment/check-user",
      userData,
      {
        headers: {
          "Idempotence-Key": uuidv4(),
        },
      },
    );
  },

  async checkInternalPayment(data: UserData & { InternalPayId: string }) {
    return await AppInstance.post("payment/check-internal-payment", data);
  },

  async createInternalPayment(
    data: UserData & {
      InternalPayId: string;
      amount: string | number;
      payDate: string;
    },
  ) {
    return await AppInstance.post("payment/create-internal-payment", data);
  },
};
