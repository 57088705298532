import { City } from "@shared/types/cities";
import { Service } from "@shared/types/services";

type TCitiesMappedToServices = {
  [key in City]: Service[];
};

const CitiesMappedToServices: TCitiesMappedToServices = {
  kanash: ["tv", "intercom", "internet"],
  yadrin: ["tv", "intercom", "internet"],
  pavlovo: ["tv", "intercom", "internet"],
  vurnary: ["tv", "intercom", "internet"],
  vorsma: ["tv", "intercom"],
  sosnovskoe: ["intercom"],
  shumerlya: ["intercom"],
};

export { CitiesMappedToServices };
