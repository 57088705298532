import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { PaymentInitialType } from "@entities/Payment/model/payment.types";
import { checkUser } from "@entities/Payment/model/thunks/payment.checkUser";

const checkUserExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentInitialType>,
) => {
  builder.addCase(checkUser.pending, (state: PaymentInitialType) => {
    state.checkUserRequestStatus = "pending";
    state.login = "";
    state.fullName = "";
    state.accountBalance = "";
  });

  builder.addCase(
    checkUser.fulfilled,
    (state: PaymentInitialType, { payload }) => {
      state.checkUserRequestStatus = "fulfilled";
      state.fullName = payload.fullName;
      state.accountBalance = payload.accountBalance;
      state.selectedTab = "user-info";
      if (payload.login) {
        state.login = payload.login;
      }
    },
  );

  builder.addCase(
    checkUser.rejected,
    (
      state: PaymentInitialType,
      { payload: errorMessage = "Неизвестная ошибка" },
    ) => {
      state.checkUserRequestStatus = "rejected";
      state.checkUserErrorMessage = errorMessage;
    },
  );
};

export { checkUserExtraReducer };
