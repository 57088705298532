import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@app/store";
import { paymentNamespace } from "@entities/Payment/model/payment.constants";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { City } from "@shared/types/cities";
import { Service } from "@shared/types/services";
import { Company } from "@shared/types/companies";

type CreatePaymentParams = {
  router: AppRouterInstance;
  amount: number | string;
  city: City;
  service: Service;
  company: Company;
  personalAccount: string;
};

const createPayment = createAsyncThunk<
  void,
  CreatePaymentParams,
  ThunkApiConfig
>(
  `${paymentNamespace}/createPayment`,
  async (
    { amount, router, city, service, company, personalAccount },
    { rejectWithValue },
  ) => {
    try {
      const response = await fetch("/api/payment/create-payment", {
        method: "POST",
        body: JSON.stringify({
          amount,
          company,
          city,
          service,
          personalAccount,
        }),
      });

      const { confirmationUrl, status, message } = await response.json();

      if (!confirmationUrl || status === "error") {
        return rejectWithValue(message);
      }

      router.push(confirmationUrl);

      return confirmationUrl;
    } catch (error) {
      return rejectWithValue(
        "Неизвестная ошибка. Пожалуйста, попробуйте внести оплату позже",
      );
    }
  },
);

export { createPayment };
