"use client";

import React, { ChangeEvent } from "react";

import { Select, SelectItem } from "@nextui-org/select";
import { City } from "@shared/types/cities";
import { CompaniesMappedToCities } from "@shared/constants/CompaniesMappedToCities";
import { Company } from "@shared/types/companies";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { paymentActions, paymentSelectors } from "@entities/Payment";
import { Cities } from "@shared/constants/cities";

function SelectCity() {
  const dispatch = useAppDispatch();
  const company = useAppSelector(paymentSelectors.selectCompany);
  const city = useAppSelector(paymentSelectors.selectCity);

  function handleChangeCity(event: ChangeEvent<HTMLSelectElement>) {
    dispatch(paymentActions.setPaymentCity(event.target.value as City));
  }

  const CitiesList = Cities.filter((city) =>
    CompaniesMappedToCities[company as Company]?.includes(city.value),
  ).map((city) => <SelectItem key={city.value}>{city.label}</SelectItem>);

  return (
    <Select
      label="Выберите город"
      className="max-w-full"
      color={city ? "success" : "default"}
      variant="bordered"
      selectedKeys={[city]}
      isRequired
      onChange={handleChangeCity}
    >
      {CitiesList}
    </Select>
  );
}

export { SelectCity };
