"use client";

import React from "react";
import { PersonalInfo } from "@widgets/Payment/ui/PersonalInfo/PersonalInfo";
import { Tab, Tabs } from "@nextui-org/tabs";
import { PaymentForm } from "@widgets/Payment/ui/PaymentForm/PaymentForm";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { paymentActions, paymentSelectors } from "@entities/Payment";
import { SelectedPaymentTab } from "@shared/types/selectedTab";

function Payment() {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(paymentSelectors.getSelectedPaymentTab);
  return (
    <Tabs
      aria-label="Информационная панель"
      selectedKey={selectedTab}
      onSelectionChange={(key) =>
        dispatch(paymentActions.setPaymentTab(key as SelectedPaymentTab))
      }
    >
      <Tab
        key="payment-form"
        title="Введите данные"
        className="max-w-md w-full"
      >
        <PaymentForm />
      </Tab>
      <Tab
        key="user-info"
        title="Информация пользователя"
        className="max-w-md w-full"
      >
        <PersonalInfo />
      </Tab>
    </Tabs>
  );
}

export { Payment };
