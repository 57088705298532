import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@app/store";
import { paymentNamespace } from "@entities/Payment/model/payment.constants";
import { UserData } from "@shared/types/user-data";
import { appApi } from "@shared/api/app/app.api";
import { PaymentUserInfo } from "@shared/types/paymentUserInfo";

type CheckUserFulfilledValue = {
  fullName: string;
  accountBalance: string;
  login: string;
};

const checkUser = createAsyncThunk<
  PaymentUserInfo,
  UserData,
  ThunkApiConfig<string>
>(
  `${paymentNamespace}/checkUser`,
  async ({ company, city, service, personalAccount }, { rejectWithValue }) => {
    try {
      const { data, ...response } = await appApi.checkUserForPayment({
        city,
        service,
        company,
        personalAccount,
      });

      if (data.status === "error") {
        return rejectWithValue(data!.message!);
      }

      return data!.data!;
    } catch (error) {
      if (!(error instanceof Error)) {
        return rejectWithValue("Неизвестная ошибка");
      }
      return rejectWithValue(error.message);
    }
  },
);

export { checkUser };
