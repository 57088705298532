import { PaymentInitialType } from "@entities/Payment/model/payment.types";
import { PayloadAction } from "@reduxjs/toolkit";
import { Service } from "@shared/types/services";
import { City } from "@shared/types/cities";
import { CitiesMappedToServices } from "@shared/constants/CitiesMappedToServices";
import { Company } from "@shared/types/companies";
import { CompaniesMappedToCities } from "@shared/constants/CompaniesMappedToCities";
import { SelectedPaymentTab } from "@shared/types/selectedTab";

export const paymentReducers = {
  setPersonalAccount: (
    state: PaymentInitialType,
    { payload: personalAccount }: PayloadAction<string>,
  ) => {
    if (state.checkUserErrorMessage) {
      state.checkUserErrorMessage = "";
    }
    if (state.checkUserRequestStatus !== "initial") {
      state.checkUserRequestStatus = "initial";
    }
    state.personalAccount = personalAccount;
  },
  setPaymentService: (
    state: PaymentInitialType,
    { payload: service }: PayloadAction<Service>,
  ) => {
    if (state.checkUserErrorMessage) {
      state.checkUserErrorMessage = "";
    }
    if (state.checkUserRequestStatus !== "initial") {
      state.checkUserRequestStatus = "initial";
    }
    state.service = service;
  },

  setPaymentCity: (
    state: PaymentInitialType,
    { payload: city }: PayloadAction<City>,
  ) => {
    if (state.checkUserErrorMessage) {
      state.checkUserErrorMessage = "";
    }
    if (state.checkUserRequestStatus !== "initial") {
      state.checkUserRequestStatus = "initial";
    }
    if (!city) {
      state.city = "";
      return;
    }

    if (!CitiesMappedToServices[city]?.includes(state.service as Service)) {
      state.city = city;
      state.service = "";
      return;
    }

    state.city = city;
  },

  setPaymentCompany: (
    state: PaymentInitialType,
    { payload: company }: PayloadAction<Company>,
  ) => {
    if (state.checkUserErrorMessage) {
      state.checkUserErrorMessage = "";
    }

    if (state.checkUserRequestStatus !== "initial") {
      state.checkUserRequestStatus = "initial";
    }

    if (!company) {
      state.company = "";
    }

    if (!CompaniesMappedToCities[company]?.includes(state.city as City)) {
      state.company = company;
      state.service = "";
      state.city = "";
      return;
    }

    if (
      !CitiesMappedToServices[state.city as City]?.includes(
        state.service as Service,
      )
    ) {
      state.company = company;
      state.service = "";
      return;
    }
    state.company = company;
  },
  setPaymentTab: (
    state: PaymentInitialType,
    { payload: tab }: PayloadAction<SelectedPaymentTab>,
  ) => {
    state.selectedTab = tab;
  },
  setPaymentAmount: (
    state: PaymentInitialType,
    { payload: amount }: PayloadAction<string | number>,
  ) => {
    if (state.createPaymentErrorMessage) {
      state.createPaymentErrorMessage = "";
    }
    if (state.createPaymentRequestStatus !== "initial") {
      state.createPaymentRequestStatus = "initial";
    }
    state.paymentAmount = amount;
  },

  setCheckUserErrorMessage: (
    state: PaymentInitialType,
    { payload: errorMessage }: PayloadAction<string>,
  ) => {
    state.checkUserErrorMessage = errorMessage;
  },
};
