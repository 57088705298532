import React from "react";
import { Card, CardBody, CardHeader } from "@nextui-org/card";
import { subtitle, title } from "@shared/lib/primitives/primitives";
import { Divider } from "@nextui-org/divider";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { paymentActions, paymentSelectors } from "@entities/Payment";
import { Input } from "@nextui-org/input";
import { Button } from "@nextui-org/button";
import { createPayment } from "@entities/Payment/model/thunks/payment.createPayment";
import { useRouter } from "next/navigation";
import { City } from "@shared/types/cities";
import { Service } from "@shared/types/services";
import { Company } from "@shared/types/companies";

function PersonalInfo() {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const fullName = useAppSelector(paymentSelectors.selectFullName);
  const login = useAppSelector(paymentSelectors.selectLogin);
  const accountBalance = useAppSelector(paymentSelectors.selectAccountBalance);
  const city = useAppSelector(paymentSelectors.selectCity);
  const company = useAppSelector(paymentSelectors.selectCompany);
  const service = useAppSelector(paymentSelectors.selectService);
  const createPaymentRequestStatus = useAppSelector(
    paymentSelectors.getCreatePaymentRequestStatus,
  );
  const createPaymentErrorMessage = useAppSelector(
    paymentSelectors.getCreatePaymentErrorMessage,
  );
  const personalAccount = useAppSelector(
    paymentSelectors.selectPersonalAccount,
  );
  const isCreatePaymentDisabled = useAppSelector(
    paymentSelectors.isCreatePaymentDisabled,
  );
  const paymentAmount = useAppSelector(paymentSelectors.getPaymentAmount);

  function createPaymentClick() {
    dispatch(
      createPayment({
        amount: paymentAmount,
        router: router,
        city: city as City,
        service: service as Service,
        personalAccount,
        company: company as Company,
      }),
    );
  }

  function handleAmountChange(event: React.ChangeEvent<HTMLInputElement>) {
    const regex = /^\d*(\.\d*)?$/;
    const value = event.target.value;
    if (!regex.test(value) && value !== "") {
      return;
    }

    dispatch(paymentActions.setPaymentAmount(event.target.value));
  }

  return (
    <Card>
      <CardHeader>
        <p className={title({ size: "esm" })}>Информация по лицевому счету</p>
      </CardHeader>
      <Divider />
      <CardBody className="gap-3">
        {!!fullName && (
          <span className={subtitle({ color: "grey" })}>
            Ваше ФИО: <span className={subtitle()}>{fullName}</span>
          </span>
        )}
        {!!login && (
          <span className={subtitle({ color: "grey" })}>
            Ваш логин: <span className={subtitle()}>{login}</span>
          </span>
        )}
        {!!accountBalance && (
          <span className={subtitle({ color: "grey" })}>
            Ваш баланс: <span className={subtitle()}>{accountBalance} RUB</span>
          </span>
        )}
        <div className="flex items-center justify-center gap-2 w-full">
          <Input
            label="Сумма пополнения"
            placeholder="Введите сумму пополнения"
            type="text"
            size="md"
            color="primary"
            variant="bordered"
            value={paymentAmount as string}
            onChange={handleAmountChange}
            isInvalid={!!createPaymentErrorMessage}
            errorMessage={createPaymentErrorMessage}
          />
          <Button
            variant="shadow"
            color="primary"
            type="submit"
            size="lg"
            onClick={createPaymentClick}
            isDisabled={isCreatePaymentDisabled}
            isLoading={createPaymentRequestStatus === "pending"}
          >
            {createPaymentRequestStatus === "pending" ? "" : "Оплатить"}
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}

export { PersonalInfo };
