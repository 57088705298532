"use client";

import React from "react";
import { Accordion, AccordionItem } from "@nextui-org/accordion";
import { subtitle } from "@shared/lib/primitives/primitives";
import { Link } from "@nextui-org/link";

const Faq = [
  {
    key: "Как оплатить",
    title: "Как оплатить",
    Component: (
      <ol
        className={
          "flex flex-col gap-2 list-decimal list-inside " +
          subtitle({ color: "grey" })
        }
      >
        <li>Введите лицевой счет.</li>
        <li>
          Узнать номер своего счета можно по номерам телефона:{" "}
          <span className={subtitle({ underlined: true })}>8(960)3022060</span>{" "}
          <span className={subtitle({ underlined: true })}>8(962)5161631</span>
        </li>
        <li>
          После ввода лицевого счета, проверьте персональные данные, которые
          привязаны к счету и оплачивайте.
        </li>
      </ol>
    ),
  },
  {
    key: "Реквизиты предприятия",
    title: "Реквизиты предприятия",
    Component: (
      <ul className={"list-disc list-inside " + subtitle({ color: "grey" })}>
        <li>Наименование предприятия: ООО "Телеком ЛТД"</li>
        <li>ИНН предприятия: 2119003877</li>
        <li>
          Адрес предприятия: 429060, Чувашская Республика, г.Ядрин, ул.50 лет
          Октября, д.90А
        </li>
        <li>БИК: 049706609</li>
        <li>Кор/счет: 30101810300000000609</li>
        <li>Р/счет: 40702810675000000638</li>
      </ul>
    ),
  },
  {
    key: "Политика конфиденциальности",
    title: "Политика конфиденциальности",
    Component: (
      <p className={subtitle({ color: "grey" })}>
        Здесь Вы можете ознакомиться с политикой конфиденциальности:{" "}
        <Link href="/politika.pdf" target="_blank">
          Политика конфиденциальности
        </Link>
      </p>
    ),
  },
  {
    key: "Описание процесса передачи данных",
    title: "Описание процесса передачи данных",
    Component: (
      <div className="flex flex-col gap-3">
        <p className={subtitle({ color: "grey" })}>
          Для оплаты покупки. Вы будете перенаправлены на платежный шлюз ПАО
          "Сбербанк России" для ввода реквизитов Вашей карты. Пожалуйста,
          приготовьте Вашу пластиковую карту заранее. Соединение с платежным
          шлюзом и передача информации осуществляется в защищенном режиме с
          использованием протокола шифрования SSL.
        </p>
        <p className={subtitle({ color: "grey" })}>
          В случае если Ваш банк поддерживает технологию безопасного проведения
          интернет-платежей Verified By Visa, MasterCard Secure Code, для
          проведения платежа также может потребоваться ввод специального пароля.
          Способы и возможность получения паролей для совершения
          интернет-платежей Вы можете уточнить в банке, выпустившем карту.
        </p>
        <p className={subtitle({ color: "grey" })}>
          Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность
          сообщаемой персональной информации обеспечивается ПАО "Сбербанк
          России". Введенная информация не будет предоставлена третьим лицам за
          исключением случаев, предусмотренных законодательством РФ. Проведение
          платежей по банковским картам осуществляется в строгом соответствии с
          требованиями платежных систем Visa Int., MasterCard Europe Sprl, МИР.
        </p>
      </div>
    ),
  },
  {
    title: "Описание процессa оплаты",
    key: "Описание процессa оплаты",
    Component: (
      <span className={subtitle({ color: "grey" })}>
        Абонент вводит номер своего лицевого счета, а также сумму ежемесячного
        платежа за оказываемую услугу. После завершения ввода всех данных, а
        также проверки своих персональных данных, Вы должны будете нажать на
        кнопку «Оплатить», при этом система переключит Вас на страницу
        авторизационного сервера, где Вам будет предложено ввести данные
        пластиковой карты, инициировать ее авторизацию, после чего вернуться в
        наш сайт. После того, как Вы возвращаетесь на наш сайт, система уведомит
        Вас о результатах авторизации. В случае подтверждения авторизации Ваша
        услуга будет автоматически оплачена в системе учета предприятия. В
        случае отказа в авторизации карты Вы сможете повторить процедуру оплаты.
      </span>
    ),
  },
  {
    title: "Возврат",
    key: "Возврат",
    Component: (
      <span className={subtitle({ color: "grey" })}>
        Возврат денежных средств производится при ошибочных зачислениях по
        заявлению клиента.
      </span>
    ),
  },
];

function PaymentFaq() {
  return (
    <Accordion
      variant="light"
      className="max-w-3xl"
      defaultExpandedKeys={["Как оплатить"]}
      isCompact={true}
    >
      {Faq.map((faq) => (
        <AccordionItem aria-label={faq.title} key={faq.key} title={faq.title}>
          {faq.Component}
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export { PaymentFaq };
