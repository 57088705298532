"use client";

import React from "react";

import { Select, SelectItem } from "@nextui-org/select";
import { Services } from "@shared/constants/services";
import { Service } from "@shared/types/services";
import { CitiesMappedToServices } from "@shared/constants/CitiesMappedToServices";
import { City } from "@shared/types/cities";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { paymentActions, paymentSelectors } from "@entities/Payment";

function SelectService() {
  const dispatch = useAppDispatch();
  const service = useAppSelector(paymentSelectors.selectService);
  const city = useAppSelector(paymentSelectors.selectCity);

  return (
    <Select
      label="Выберите услугу"
      className="max-w-full"
      color={service ? "success" : "default"}
      variant="bordered"
      selectedKeys={[service]}
      isRequired
      onChange={(event) =>
        dispatch(
          paymentActions.setPaymentService(event.target.value as Service),
        )
      }
    >
      {Services.filter((service) =>
        CitiesMappedToServices[city as City]?.includes(service.value),
      ).map((service) => (
        <SelectItem key={service.value}>{service.label}</SelectItem>
      ))}
    </Select>
  );
}

export { SelectService };
