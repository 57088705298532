import React, { FormEvent } from "react";
import { Card, CardBody } from "@nextui-org/card";
import { Input } from "@nextui-org/input";
import { paymentActions, paymentSelectors } from "@entities/Payment";
import { Button } from "@nextui-org/button";
import { checkUser } from "@entities/Payment/model/thunks/payment.checkUser";
import { SelectCompany } from "@features/SelectCompany/SelectCompany";
import { SelectCity } from "@features/SelectCity/SelectCity";
import { SelectService } from "@features/SelectService/SelectService";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { RightArrowIcon } from "@ui/icons/RightArrowIcon";
import {
  getMinMaxLengthPersonalAccount,
  personalAccountLengthByService,
} from "@shared/constants/personalAccountLengthByService";
import { Service } from "@shared/types/services";
import { City } from "@shared/types/cities";

function PaymentForm() {
  const dispatch = useAppDispatch();
  const city = useAppSelector(paymentSelectors.selectCity);
  const company = useAppSelector(paymentSelectors.selectCompany);
  const service = useAppSelector(paymentSelectors.selectService);
  const checkUserErrorMessage = useAppSelector(
    paymentSelectors.getCheckUserErrorMessage,
  );
  const checkUserRequestStatus = useAppSelector(
    paymentSelectors.selectCheckUserRequestStatus,
  );
  const personalAccount = useAppSelector(
    paymentSelectors.selectPersonalAccount,
  );

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const cityServices = personalAccountLengthByService[service as Service];

    if (!cityServices) {
      return;
    }

    const data = cityServices[city as City];

    if (!data) {
      return;
    }

    const { minLength, maxLength } = data;

    if (
      personalAccount.length > maxLength ||
      personalAccount.length < minLength
    ) {
      dispatch(
        paymentActions.setCheckUserErrorMessage(
          getMinMaxLengthPersonalAccount({
            city: city as City,
            service: service as Service,
          }),
        ),
      );
      return;
    }

    await dispatch(checkUser({ company, city, service, personalAccount }));
  }

  const Inputs = [
    { Component: SelectCompany, value: company },
    { Component: SelectCity, value: city },
    { Component: SelectService, value: service },
  ];

  const isSubmitDisabled =
    !city || !service || !company || checkUserRequestStatus === "pending";

  const isInputDescriptionVisible = city && service && company;

  let inputDescription = "";
  if (isInputDescriptionVisible) {
    inputDescription = getMinMaxLengthPersonalAccount({ city, service });
  }

  return (
    <Card>
      <CardBody>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center justify-center gap-3"
        >
          {Inputs.map((Input, index) => (
            <div
              key={`payment_input_${index}`}
              className="flex justify-center items-center gap-2 w-full"
            >
              <Input.Component />
            </div>
          ))}
          <div className="flex justify-center gap-2 w-full">
            <Input
              label="Лицевой счет"
              placeholder="Введите ваш лицевой счет"
              type="text"
              value={personalAccount}
              onValueChange={(value) => {
                dispatch(paymentActions.setPersonalAccount(value));
              }}
              isDisabled={isSubmitDisabled}
              size="sm"
              color="default"
              variant="bordered"
              errorMessage={checkUserErrorMessage}
              isInvalid={!!checkUserErrorMessage}
              description={inputDescription}
            />
            <Button
              isLoading={checkUserRequestStatus === "pending"}
              variant="shadow"
              color={personalAccount ? "success" : "default"}
              type="submit"
              size="lg"
              isDisabled={isSubmitDisabled || !personalAccount}
            >
              {checkUserRequestStatus === "pending" ? "" : <RightArrowIcon />}
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}

export { PaymentForm };
