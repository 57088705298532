import { PaymentInitialType } from "@entities/Payment/model/payment.types";

export const paymentSelectors = {
  selectCity: (state: PaymentInitialType) => state.city,
  selectCompany: (state: PaymentInitialType) => state.company,
  selectService: (state: PaymentInitialType) => state.service,
  selectPersonalAccount: (state: PaymentInitialType) => state.personalAccount,
  selectLogin: (state: PaymentInitialType) => state.login,
  selectCheckUserRequestStatus: (state: PaymentInitialType) =>
    state.checkUserRequestStatus,
  getCheckUserErrorMessage: (state: PaymentInitialType) =>
    state.checkUserErrorMessage,
  getCreatePaymentErrorMessage: (state: PaymentInitialType) =>
    state.createPaymentErrorMessage,
  selectAccountBalance: (state: PaymentInitialType) => state.accountBalance,
  selectFullName: (state: PaymentInitialType) => state.fullName,
  isPersonalInfoVisible: (state: PaymentInitialType) => {
    const { accountBalance, login, fullName, checkUserRequestStatus } = state;
    return (
      accountBalance &&
      login &&
      fullName &&
      checkUserRequestStatus === "fulfilled"
    );
  },
  getSelectedPaymentTab: (state: PaymentInitialType) => state.selectedTab,
  getPaymentAmount: (state: PaymentInitialType) => state.paymentAmount,
  getCreatePaymentRequestStatus: (state: PaymentInitialType) =>
    state.createPaymentRequestStatus,
  isCreatePaymentDisabled: (state: PaymentInitialType) => {
    const {
      paymentAmount,
      createPaymentRequestStatus,
      checkUserRequestStatus,
    } = state;
    return (
      !paymentAmount ||
      createPaymentRequestStatus === "pending" ||
      checkUserRequestStatus !== "fulfilled"
    );
  },
};
