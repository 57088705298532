import { PaymentInitialType } from "@entities/Payment/model/payment.types";

export const paymentInitialState: PaymentInitialType = {
  accountBalance: 0,
  login: "",
  fullName: "",
  personalAccount: "",
  company: "",
  city: "",
  service: "",

  checkUserRequestStatus: "initial",
  checkUserErrorMessage: "",

  createPaymentRequestStatus: "initial",
  createPaymentErrorMessage: "",

  paymentAmount: "",
  selectedTab: "payment-form",
};
