import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { PaymentInitialType } from "@entities/Payment/model/payment.types";
import { createPayment } from "@entities/Payment/model/thunks/payment.createPayment";

const createPaymentExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentInitialType>,
) => {
  builder.addCase(createPayment.pending, (state: PaymentInitialType) => {
    state.createPaymentRequestStatus = "pending";
  });

  builder.addCase(
    createPayment.fulfilled,
    (state: PaymentInitialType, { payload }) => {
      state.createPaymentRequestStatus = "fulfilled";
    },
  );

  builder.addCase(
    createPayment.rejected,
    (state: PaymentInitialType, { payload: errorMessage = "" }) => {
      state.createPaymentRequestStatus = "rejected";
      state.createPaymentErrorMessage = errorMessage;
    },
  );
};

export { createPaymentExtraReducer };
