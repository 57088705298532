import { createSlice } from "@reduxjs/toolkit";
import { paymentNamespace } from "@entities/Payment/model/payment.constants";
import { paymentInitialState } from "@entities/Payment/model/payment.initialState";
import { paymentExtraReducers } from "@entities/Payment/model/payment.extraReducers";
import { paymentReducers } from "@entities/Payment/model/payment.reducers";
import { paymentSelectors } from "@entities/Payment/model/payment.selectors";

const paymentSlice = createSlice({
  name: paymentNamespace,
  initialState: paymentInitialState,
  selectors: paymentSelectors,
  reducers: paymentReducers,
  extraReducers: paymentExtraReducers,
});

export { paymentSlice };
