import { Service } from "@shared/types/services";
import { City } from "@shared/types/cities";
import { CitiesLabels } from "@shared/constants/cities";
import { ServicesLabels } from "@shared/constants/services";

export const personalAccountLengthByService: {
  [key in Service]: {
    [key in City]?: {
      minLength: number;
      maxLength: number;
    };
  };
} = {
  internet: {
    yadrin: {
      minLength: 1,
      maxLength: 5,
    },
    kanash: {
      minLength: 1,
      maxLength: 5,
    },
    pavlovo: {
      minLength: 1,
      maxLength: 5,
    },
    vurnary: {
      minLength: 1,
      maxLength: 5,
    },
  },
  tv: {
    yadrin: {
      minLength: 1,
      maxLength: 5,
    },
    kanash: {
      minLength: 1,
      maxLength: 5,
    },
    vurnary: {
      minLength: 9,
      maxLength: 9,
    },
    pavlovo: {
      minLength: 10,
      maxLength: 10,
    },
    vorsma: {
      minLength: 10,
      maxLength: 10,
    },
  },
  intercom: {
    yadrin: {
      minLength: 1,
      maxLength: 5,
    },
    kanash: {
      minLength: 1,
      maxLength: 5,
    },
    vurnary: {
      minLength: 9,
      maxLength: 9,
    },
    pavlovo: {
      minLength: 10,
      maxLength: 10,
    },
    vorsma: {
      minLength: 10,
      maxLength: 10,
    },
    sosnovskoe: {
      minLength: 10,
      maxLength: 10,
    },
    shumerlya: {
      minLength: 1,
      maxLength: 4,
    },
  },
};

export function getMinMaxLengthPersonalAccount({
  city,
  service,
}: {
  city: City;
  service: Service;
}) {
  const cityServices = personalAccountLengthByService[service];

  if (!cityServices) {
    return "";
  }

  const data = cityServices[city];

  if (!data) {
    return "";
  }

  const { minLength, maxLength } = data;

  if (minLength !== maxLength) {
    return `Для города ${CitiesLabels[city]} и услуги ${ServicesLabels[service]} количество символов в лицевом счете может быть от ${minLength} до ${maxLength}`;
  }

  return `Для города ${CitiesLabels[city]} и услуги ${ServicesLabels[service]} количество символов в лицевом счете должно быть равно ${maxLength}`;
}
