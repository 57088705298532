import { Company } from "@shared/types/companies";

const CompaniesLabels: {
  [key in Company]: string;
} = {
  telekom_ltd: "Телеком ЛТД",
  magnit: "Магнит ТВ",
};

const Companies: { name: Company; value: Company; label: string }[] = [
  {
    label: "Магнит ТВ",
    name: "magnit",
    value: "magnit",
  },
  {
    label: "Телеком ЛТД",
    name: "telekom_ltd",
    value: "telekom_ltd",
  },
];

export { Companies, CompaniesLabels };
