"use client";

import React, { ChangeEvent } from "react";
import { Select, SelectItem } from "@nextui-org/select";
import { Companies } from "@shared/constants/companies";
import { Company } from "@shared/types/companies";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { paymentActions, paymentSelectors } from "@entities/Payment";

function SelectCompany() {
  const dispatch = useAppDispatch();
  const company = useAppSelector(paymentSelectors.selectCompany);

  function handleChangeCity(event: ChangeEvent<HTMLSelectElement>) {
    dispatch(paymentActions.setPaymentCompany(event.target.value as Company));
  }

  return (
    <Select
      label="Выберите организацию"
      className="max-w-full"
      variant="bordered"
      selectedKeys={[company]}
      isRequired
      onChange={handleChangeCity}
      color={company ? "success" : "default"}
    >
      {Companies.map((company) => (
        <SelectItem key={company.value}>{company.label}</SelectItem>
      ))}
    </Select>
  );
}

export { SelectCompany };
