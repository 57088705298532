import { City } from "@shared/types/cities";

const CitiesLabels: { [key in City]: string } = {
  yadrin: "Ядрин",
  kanash: "Канаш",
  vorsma: "Ворсма",
  pavlovo: "Павлово",
  vurnary: "Вурнары",
  sosnovskoe: "Сосновское",
  shumerlya: "Шумерля",
};

const Cities: { name: City; value: City; label: string }[] = [
  {
    label: CitiesLabels.yadrin,
    name: "yadrin",
    value: "yadrin",
  },
  {
    label: CitiesLabels.kanash,
    name: "kanash",
    value: "kanash",
  },
  {
    label: CitiesLabels.pavlovo,
    name: "pavlovo",
    value: "pavlovo",
  },
  {
    label: CitiesLabels.vurnary,
    name: "vurnary",
    value: "vurnary",
  },
  {
    label: CitiesLabels.sosnovskoe,
    name: "sosnovskoe",
    value: "sosnovskoe",
  },
  {
    label: CitiesLabels.vorsma,
    name: "vorsma",
    value: "vorsma",
  },
  {
    label: CitiesLabels.shumerlya,
    name: "shumerlya",
    value: "shumerlya",
  },
];

export { Cities, CitiesLabels };
