import { Service } from "@shared/types/services";

const ServicesLabels: {
  [key in Service]: string;
} = {
  intercom: "Домофония",
  internet: "Интернет",
  tv: "Телевидение",
};

const Services: { name: Service; value: Service; label: string }[] = [
  {
    label: "Интернет",
    name: "internet",
    value: "internet",
  },
  {
    label: "Телевидение",
    name: "tv",
    value: "tv",
  },
  {
    label: "Домофония",
    name: "intercom",
    value: "intercom",
  },
];

export { Services, ServicesLabels };
