import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { PaymentInitialType } from "@entities/Payment/model/payment.types";
import { checkUserExtraReducer } from "@entities/Payment/model/extraReducers/checkUserExtraReducer";
import { createPaymentExtraReducer } from "@entities/Payment/model/extraReducers/createPaymentExtraReducer";

export const paymentExtraReducers = (
  builder: ActionReducerMapBuilder<PaymentInitialType>,
) => {
  checkUserExtraReducer(builder);
  createPaymentExtraReducer(builder);
};
