import { City } from "@shared/types/cities";
import { Company } from "@shared/types/companies";

type TCompaniesMappedToCities = {
  [key in Company]: City[];
};

const CompaniesMappedToCities: TCompaniesMappedToCities = {
  magnit: ["kanash"],
  telekom_ltd: [
    "yadrin",
    "pavlovo",
    "sosnovskoe",
    "vurnary",
    "vorsma",
    "shumerlya",
  ],
};

export { CompaniesMappedToCities };
